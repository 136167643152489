<template>
  <v-container fluid>
    <v-card>
      <FormToolbar :title="titleText" />
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-card-title>Акція</v-card-title>
            <v-switch v-model="isActive" color="success" :label="isActive ? 'Увімкнуто' : 'Вимкнуто'" :disabled="isViewMode" />
            <v-radio-group v-model="isGlobal" :disabled="!!promotionId || isViewMode" row>
              <v-radio :value="false" label="Локальна" />
              <v-radio :value="true" label="Глобальна" />
            </v-radio-group>
            <v-select
              v-model="is_available_once"
              :items="promotionTypes()"
              :disabled="!!promotionId || isGlobal || isViewMode"
              item-text="value"
              item-value="isOneTime"
              label="Вид акції"
              outlined
              dense
              @change="(value) => (this.editedItem.is_available_once = value)"
            />
            <v-select
              v-model="type"
              :items="isGlobal ? globalPromotionsDictionary : companyPromotionsDictionaryFiltered"
              :disabled="!!promotionId || isViewMode"
              item-text="name"
              item-value="value"
              label="Тип акції"
              outlined
              dense
            />
            <v-text-field
              v-if="!!currentTypeParams && currentTypeParams.title && !currentTypeParams.title.hide"
              v-model="editedItem.title"
              :disabled="!currentTypeParams.title.editable || isViewMode"
              :error-messages="inputErrors('title')"
              label="Назва акції"
              outlined
              dense
              @blur="$v.editedItem.title.$touch()"
              @input="$v.editedItem.title.$touch()"
            />
            <v-text-field v-else label="Назва акції" outlined dense disabled />
            <DatePicker
              v-if="!!currentTypeParams && currentTypeParams.start_at && !currentTypeParams.start_at.hide"
              :disabled="!currentTypeParams.start_at.editable || isViewMode"
              :value="editedItem.start_at"
              :error-messages="inputErrors('start_at')"
              label="Термін дії з"
              outlined
              dense
              @change="(date) => (editedItem = { ...editedItem, start_at: date })"
              @blur="$v.editedItem.start_at.$touch()"
              @input="$v.editedItem.start_at.$touch()"
            />
            <DatePicker v-else label="Термін дії з" outlined dense disabled />
            <DatePicker
              v-if="!!currentTypeParams && currentTypeParams.expired_at && !currentTypeParams.expired_at.hide"
              :disabled="!currentTypeParams.expired_at.editable || isViewMode"
              :value="editedItem.expired_at"
              :error-messages="inputErrors('expired_at')"
              label="Термін дії до"
              outlined
              dense
              @change="(date) => (editedItem = { ...editedItem, expired_at: date })"
              @blur="$v.editedItem.expired_at.$touch()"
              @input="$v.editedItem.expired_at.$touch()"
            />
            <DatePicker v-else label="Термін дії до" outlined dense disabled />
            <div v-if="editedItem && editedItem.created_by" class="d-flex align-baseline custom-field">
              <v-text-field
                :value="`${editedItem.created_by.first_name} ${editedItem.created_by.last_name}`"
                label="Ініціатор"
                outlined
                dense
                disabled
              ></v-text-field>
            </div>
            <v-overflow-btn label="Обрати банери" dense disabled></v-overflow-btn>
            <PagingAutocomplete
              v-if="!!currentTypeParams && currentTypeParams.users && !currentTypeParams.users.hide"
              :disabled="!currentTypeParams.users.editable || isViewMode"
              :error-messages="inputErrors('users')"
              :options="clients.map(({ id, first_name, last_name }) => ({ id, first_name, last_name }))"
              :options-meta="clientsMeta"
              :options-params="usersOptions"
              :value="editedItem.users"
              clearable
              item-value="id"
              label="Клієнти"
              multiple
              outlined
              dense
              no-filter
              @blur="$v.editedItem.users.$touch()"
              @change="setUsers"
              @input="$v.editedItem.users.$touch()"
            >
              <template v-slot:item="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
              <template v-slot:selection="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
            </PagingAutocomplete>
            <v-select v-else label="Клієнти" outlined dense disabled></v-select>
            <v-select
              v-if="!!currentTypeParams && currentTypeParams.value_type && !currentTypeParams.value_type.hide"
              v-model="editedItem.value_type"
              :disabled="!currentTypeParams.value_type.editable || isViewMode"
              :items="valueTypes()"
              item-text="value"
              item-value="id"
              label="Вид бонуса"
              outlined
              dense
              @change="(valueType) => (editedItem = { ...editedItem, value_type: valueType })"
            />
            <v-select v-else label="Вид бонуса" outlined dense disabled></v-select>
            <div v-if="!!currentTypeParams && currentTypeParams.conditions && !currentTypeParams.conditions.hide">
              <v-switch
                v-model="useRanges"
                color="success"
                :label="`Задати діапазони значень ${
                  !!editedItem.conditions.length ? `(${getConditionsMinMax(editedItem.conditions)})` : ''
                }`"
              />
              <ConditionsArrayModal
                :use-ranges="useRanges"
                :values="editedItem.conditions"
                :params="currentTypeParams.conditions"
                :is-error="rangesError"
                @create="(condition) => editedItem.conditions.push(condition)"
                @update="updateCondition"
                @delete="deleteCondition"
              />
            </div>
            <v-text-field
              class="mt-4"
              v-if="!!currentTypeParams && currentTypeParams.amount && !currentTypeParams.amount.hide"
              v-model="editedItem.amount"
              :disabled="!currentTypeParams.amount.editable || (currentTypeParams.conditions && useRanges) || isViewMode"
              :error-messages="inputErrors('amount')"
              :suffix="getValueType(editedItem.value_type)"
              type="number"
              outlined
              dense
              @blur="$v.editedItem.amount.$touch()"
              @input="$v.editedItem.amount.$touch()"
              label="Розмір бонуса"
            />
            <v-text-field v-else label="Розмір бонуса" outlined dense disabled />
          </v-col>
          <v-col cols="12" sm="6" md="4" offset-md="2">
            <v-card-title>Місце</v-card-title>
            <PagingAutocomplete
              v-if="!!currentTypeParams && currentTypeParams.service_points && !currentTypeParams.service_points.hide"
              :disabled="!currentTypeParams.service_points.editable || isViewMode"
              :options="servicePoints"
              :options-meta="servicePointsMeta"
              :options-params="servicePointsOptions"
              :value="editedItem.service_points"
              :error-messages="inputErrors('service_points')"
              chips
              clearable
              deletable-chips
              item-text="name"
              item-value="id"
              label="Торгові точки"
              multiple
              outlined
              dense
              return-object
              @change="setServicePoints"
              @blur="$v.editedItem.service_points.$touch()"
              @input="$v.editedItem.service_points.$touch()"
            >
              <template v-slot:item="{ item }"> {{ item.name }} {{ getAddressText(item.address) }}) </template>
            </PagingAutocomplete>
            <v-select v-else label="Торгові точки" outlined dense disabled></v-select>
            <PagingSelect
              v-if="!!currentTypeParams && currentTypeParams.devices && !currentTypeParams.devices.hide"
              :value="editedItem.devices"
              :disabled="!currentTypeParams.devices.editable || isViewMode"
              :options="devices"
              :options-meta="devicesMeta"
              :options-params="devicesOptions"
              :error-messages="inputErrors('devices')"
              chips
              clearable
              deletable-chips
              item-value="id"
              label="Автомати"
              return-object
              multiple
              outlined
              dense
              @change="setDevices"
              @blur="$v.editedItem.devices.$touch()"
              @input="$v.editedItem.devices.$touch()"
            >
              <template v-slot:item="{ item }">{{ getDeviceTitle(item.type) }} {{ item.id }}</template>
              <template v-slot:selection="{ item }">{{ getDeviceTitle(item.type) }} {{ item.id }}</template>
            </PagingSelect>
            <v-select v-else label="Автомати" outlined dense disabled></v-select>
            <v-select
              v-if="!!currentTypeParams && currentTypeParams.device_types && !currentTypeParams.device_types.hide"
              v-model="editedItem.device_types"
              :disabled="!currentTypeParams.device_types.editable || isViewMode"
              :items="deviceTypes.map(({ id, name }) => ({ id, name }))"
              chips
              clearable
              deletable-chips
              item-text="name"
              item-value="id"
              label="Типи автоматів"
              return-object
              multiple
              outlined
              dense
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-information</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Залиште вибір порожнім для вказання всіх типів автоматів </v-list-item-title>
                    <v-divider class="mt-2"></v-divider>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
            <v-select v-else label="Типи автоматів" outlined dense disabled></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <div v-if="!!currentTypeParams && currentTypeParams.description && !currentTypeParams.description.hide">
              <vue-editor
                v-model="editedItem.description"
                :disabled="!currentTypeParams.description.editable || isViewMode"
                :error="!!inputErrors('description').length"
                :placeholder="isViewMode ? '' : 'Опис акції'"
                @blur="$v.editedItem.description.$touch()"
                @input="$v.editedItem.description.$touch()"
              />
              <div class="error-msg">
                <div v-if="!!inputErrors('description').length">{{ inputErrors('description')[0] }}</div>
              </div>
            </div>
            <vue-editor v-else label="Опис акції" disabled />
          </v-col>
        </v-row>
        <v-divider v-if="currentTypeParams && currentTypeParams.params" />
        <v-row>
          <v-col v-if="currentTypeParams && currentTypeParams.params" cols="12" sm="6" md="4">
            <v-card-title>Налаштування параметрів для мобайла</v-card-title>
            <v-checkbox
              v-if="!!currentTypeParams"
              v-model="editedItem.params.show_promotion"
              label="Відображати акцію у додатку Mate"
              :disabled="!currentTypeParams || isViewMode"
              @change="setShowInMate"
            />
            <v-checkbox v-else label="Відображати акцію у додатку Mate" disabled />
            <v-simple-table v-if="!!currentTypeParams && editedItem.params.show_promotion" dense>
              <thead>
                <tr>
                  <th>Порядок розташування</th>
                  <th>Параметр</th>
                  <th>Включено</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="[paramKey, paramValue] of flagParamsSorted" :key="paramKey">
                  <td>
                    <v-select
                      :value="paramValue.sort_priority"
                      :items="prioritiesArr"
                      :disabled="isViewMode"
                      outlined
                      dense
                      hide-details
                      @change="reorderParams(paramValue.sort_priority, $event, paramKey)"
                    />
                  </td>
                  <td>{{ getMobileParamName(paramKey) }}</td>
                  <td>
                    <v-switch
                      v-model="paramValue.enabled"
                      color="success"
                      :disabled="isViewMode"
                      @change="(value) => paramSwitchChange(value, paramKey)"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <div v-if="!!currentTypeParams && editedItem.params.show_promotion && !promotionHeaderDisabled">
              <vue-editor
                v-model="editedItem.params.promotion_header.content"
                :placeholder="isViewMode ? '' : 'Напишіть рекламний заголовок перед %. Наприклад: Зроби покупку та отримай.'"
                :disabled="isViewMode"
              />
              <div class="error-msg">
                <div v-if="!!promotionHeaderErrors().length">
                  {{ promotionHeaderErrors()[0] }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div v-if="!!currentTypeParams && !isViewMode" class="d-flex mt-4 justify-end">
          <v-btn class="mr-2" color="primary" @click="toPromotions" small outlined>Скасувати</v-btn>
          <v-btn color="primary" @click="saveClick" small>Зберегти</v-btn>
        </div>
        <div v-else class="d-flex mt-4 justify-end">
          <router-link :to="{ name: 'PromotionEdit', params: { promotionId } }">
            <v-btn color="primary" small>Редагувати</v-btn>
          </router-link>
        </div>
      </v-container>

      <AskDialog
        :is-opened="dialogAsk"
        message="Останній діапазон повинен бути необмеженим"
        sub-message="Видалити параметр 'до' в останньому діапазоні?"
        no-button-text="Продовжити редагування"
        @yes="askYes"
        @no="() => (dialogAsk = false)"
      />
    </v-card>
  </v-container>
</template>

<script>
import FormToolbar from '@/components/common/forms/FormToolbar'
import { mapActions, mapState } from 'vuex'
import CloneDeep from 'lodash/cloneDeep'
import { PROMOTION_SCHEMA } from '@/const/apiSchemas'
import DatePicker from '@/components/common/DatePicker'
import PagingAutocomplete from '@/components/common/PagingAutocomplete'
import PagingSelect from '@/components/common/PagingSelect'
import { mobileParams, PROMOTION_PARAMS, promotionTypes, valueTypes } from '@/const/promotionParams'
import { validationMixin } from 'vuelidate'
import { helpers, minLength, required } from 'vuelidate/lib/validators'
import moment from 'moment'
import convertDevicesTypes from '@/mixins/convertDevicesTypes'
import { VueEditor } from 'vue2-editor'
import AskDialog from '@/components/dialogs/AskDialog'
import ConditionsArrayModal from '@/components/marketing/promotions/ConditionsArrayModal'
import conditionsMinMax from '@/mixins/conditionsMinMax'

export default {
  name: 'PromotionEdit',
  components: {
    ConditionsArrayModal,
    AskDialog,
    PagingSelect,
    PagingAutocomplete,
    DatePicker,
    FormToolbar,
    VueEditor,
  },

  mixins: [validationMixin, convertDevicesTypes, conditionsMinMax],

  data() {
    return {
      editedItem: null,
      currentTypeParams: null,
      usersOptions: null,
      servicePointsOptions: null,
      devicesOptions: null,
      is_available_once: false,
      type: null,
      dialogAsk: false,
      useRanges: true,
      isGlobal: false,
      isActive: true,
      promotionHeaderDisabled: true,
      isAdmin: true, // Todo: make this param computed for correct endpoint usage in future
    }
  },

  validations() {
    const isDateValid = helpers.withParams(
      { type: 'isDateValid' },
      (value) => moment(value).startOf('day') >= moment(new Date()).startOf('day') || value === null
    )
    const isExpiredValid = helpers.withParams({ type: 'isExpiredValid' }, (value, vm) => {
      if (!value || !vm.start_at) return true
      return new Date(value) >= new Date(vm.start_at)
    })
    const isAmountMin = helpers.withParams({ type: 'isAmountMin' }, (value, vm) => {
      if (this.currentTypeParams.amount.minValue) return value >= this.currentTypeParams.amount.minValue
      if (vm.value_type === 1) {
        return value >= 0.01
      } else {
        return value >= 0.1
      }
    })
    const isAmountMax = helpers.withParams({ type: 'isAmountMax' }, (value) => {
      if (this.currentTypeParams.amount.maxValue) return value <= this.currentTypeParams.amount.maxValue
      return true
    })
    return {
      editedItem: {
        start_at: {
          required: this.currentTypeParams.start_at?.required ? required : false,
          isDateValid: this.currentTypeParams.start_at && !this.promotionId ? isDateValid : false,
        },
        expired_at: {
          required: this.currentTypeParams.expired_at?.required ? required : false,
          isDateValid: this.currentTypeParams.start_at ? isDateValid : false,
          isExpiredValid: this.currentTypeParams.start_at ? isExpiredValid : false,
        },
        title: { required: this.currentTypeParams.title?.required ? required : false },
        description: {
          required: this.currentTypeParams.description?.required ? required : false,
          minLength: this.currentTypeParams.description?.minLength
            ? minLength(this.currentTypeParams.description.minLength)
            : false,
        },
        users: { required: this.currentTypeParams.users?.required ? required : false },
        service_points: { required: this.currentTypeParams.service_points?.required ? required : false },
        devices: { required: this.currentTypeParams.devices?.required ? required : false },
        amount: {
          required: this.currentTypeParams.amount?.required ? required : false,
          isAmountMin: this.currentTypeParams.amount?.required ? isAmountMin : false,
          isAmountMax: this.currentTypeParams.amount?.required ? isAmountMax : false,
        },
        params: {
          promotion_header: { content: { required: this.promotionHeaderDisabled ? false : required } },
        },
      },
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    async type(val) {
      if (val) {
        if (!this.promotionId) {
          this.editedItem = CloneDeep(PROMOTION_SCHEMA)
          this.editedItem.is_available_once = this.is_available_once
        }
        this.editedItem.type = val
        this.currentTypeParams = PROMOTION_PARAMS.find((item) => item.type === val)
        if (this.currentTypeParams) {
          for (const [key, value] of Object.entries(this.currentTypeParams)) {
            if (this.editedItem[key] === undefined) {
              if (!(key in PROMOTION_SCHEMA)) {
                this.editedItem[key] = null
              }
              if (value.presetValue !== undefined) {
                this.editedItem[key] = value.presetValue
              }
            }
          }
          if (this.currentTypeParams.params) {
            this.getMobileParams()
          }
          this.$v.$reset()
        }
      }
    },

    isGlobal(val) {
      if (val) this.is_available_once = false
    },

    isActive(val) {
      this.editedItem.isActive = val
    },
  },

  computed: {
    ...mapState('promotions', ['promotion']),
    ...mapState('dictionaries', ['globalPromotionsDictionary', 'companyPromotionsDictionary', 'machinesTypesDictionary']),
    ...mapState('users', ['clients', 'clientsMeta']),
    ...mapState('servicePoints', ['servicePoints', 'servicePointsMeta']),
    ...mapState('devices', ['devices', 'devicesMeta']),
    ...mapState('companies', ['filter', 'isFilterLoaded']),
    ...mapState('settings', ['settings']),

    promotionId() {
      return this.$route.params.promotionId
    },

    isViewMode() {
      return +this.$route.query.viewMode === 1
    },

    currentCompanyId() {
      return this.$route.params.id
    },

    titleText() {
      if (this.promotionId) {
        return `${this.isViewMode ? 'Перегляд' : 'Редагування'} акції ${this.promotionId}`
      } else {
        return 'Створення нової акції'
      }
    },

    rangesError() {
      return this.$v.$dirty && this.editedItem.conditions.length === 0
    },

    companyPromotionsDictionaryFiltered() {
      return this.companyPromotionsDictionary.filter((item) => (this.is_available_once ? item.value === 6 : item.value !== 6))
    },

    flagParamsSorted() {
      return Object.entries(this.editedItem.params.flag_params).sort((a, b) => a[1].sort_priority - b[1].sort_priority)
    },

    prioritiesArr() {
      return Array.from({ length: Object.keys(this.editedItem.params.flag_params).length }, (_, i) => i + 1)
    },
  },

  methods: {
    ...mapActions('promotions', ['loadSelectedPromotion', 'createPromotion', 'updateSelectedPromotion']),
    ...mapActions('users', ['loadClients']),
    ...mapActions('servicePoints', ['loadServicePoints']),
    ...mapActions('devices', ['loadDevices']),
    ...mapActions('companies', ['loadFilter']),
    ...mapActions('settings', ['loadSettings']),

    valueTypes() {
      return valueTypes
    },
    promotionTypes() {
      return promotionTypes
    },

    async initialize() {
      await this.loadFilter(this.currentCompanyId)
      const defaultPayload = {
        companyId: this.currentCompanyId,
        forSearch: 1,
      }
      this.usersOptions = {
        loadingFunction: this.loadClients,
        payload: { ...defaultPayload },
      }
      this.servicePointsOptions = {
        loadingFunction: this.loadServicePoints,
        payload: { ...defaultPayload, forSearch: 0 },
      }
      this.devicesOptions = {
        loadingFunction: this.loadDevices,
        payload: { ...defaultPayload },
      }
      if (!this.promotionId) {
        this.editedItem = CloneDeep(PROMOTION_SCHEMA)
        return
      } else {
        await this.loadSelectedPromotion({
          companyId: this.currentCompanyId,
          isAdmin: this.isAdmin,
          promotionId: this.promotionId,
        })
        this.editedItem = CloneDeep(this.promotion)
        this.isGlobal = !this.editedItem.company
        this.is_available_once = this.editedItem.is_available_once
        this.type = this.editedItem.type
        this.isActive = this.editedItem.is_active
        if (this.editedItem.expired_at === null) {
          delete this.editedItem.expired_at
        }
      }
      if (this.promotionId) {
        this.type = this.editedItem.type
      }
    },

    getValueType(type) {
      const val = this.valueTypes().find((item) => item.id === type)
      return val?.value || null
    },

    getAddressText(address) {
      if (!address) return ''
      return `(${address.city ? address.city + ',' : ''} ${address.street}, ${address.building_number})`
    },

    getMobileParamName(key) {
      return mobileParams[key]
    },

    reorderParams(oldVal, newVal, key) {
      const otherKey = Object.entries(this.editedItem.params.flag_params).find(([, value]) => value.sort_priority === newVal)[0]
      this.editedItem.params.flag_params[key].sort_priority = newVal
      this.editedItem.params.flag_params[otherKey].sort_priority = oldVal
    },

    inputErrors(fieldName) {
      const errors = []
      if (!this.$v.editedItem[fieldName].$dirty) return errors
      if (this.$v.editedItem[fieldName].isDateValid === false || this.$v.editedItem[fieldName].isExpiredValid === false) {
        errors.push('Неприпустиме значення дати')
      }
      this.$v.editedItem[fieldName].required === false && errors.push('Обовʼязкове поле')
      this.$v.editedItem[fieldName].minLength === false &&
        errors.push(`Мінімальна довжина - ${this.$v.editedItem[fieldName].$params.minLength.min} символів`)
      this.$v.editedItem[fieldName].isAmountMin === false &&
        errors.push(
          `Введіть значення від ${(() => {
            if (this.currentTypeParams[fieldName].minValue) return this.currentTypeParams[fieldName].minValue
            return this.editedItem.value_type === 1 ? 0.01 : 0.1
          })()}`
        )
      this.$v.editedItem[fieldName].isAmountMax === false &&
        errors.push(`Введіть значення до ${this.currentTypeParams[fieldName].maxValue}`)
      return errors
    },

    promotionHeaderErrors() {
      const errors = []
      if (!this.$v.editedItem.params.promotion_header.content.$dirty) return errors
      this.$v.editedItem.params.promotion_header.content.required === false && errors.push('Обовʼязкове поле')
      return errors
    },

    setUsers(users) {
      this.editedItem.users = users
    },

    setServicePoints(points) {
      this.editedItem = { ...this.editedItem, service_points: [...points] }
    },

    setDevices(devices) {
      this.editedItem = { ...this.editedItem, devices: [...devices] }
    },

    getMobileParams() {
      const fromMobileParams = Object.fromEntries(
        Object.entries(mobileParams)
          .filter(([key]) => this.currentTypeParams.params.includes(key))
          .map(([key], index) => [key, { enabled: false, sort_priority: index + 1 }])
      )
      this.editedItem.params.flag_params = Object.entries(this.editedItem.params.flag_params).filter(
        ([, value]) => !!value.sort_priority
      ).length
        ? {
            ...Object.fromEntries(
              Object.entries(this.editedItem.params.flag_params).filter(([, value]) => !!value.sort_priority)
            ),
          }
        : fromMobileParams
      if (this.currentTypeParams.params.includes('promotion_header')) {
        if (this.promotionId) {
          this.editedItem.params.flag_params.promotion_header = {
            enabled: !!this.editedItem.params.promotion_header.content,
            sort_priority: this.editedItem.params.promotion_header.sort_priority || 1,
          }
          this.promotionHeaderDisabled = !this.editedItem.params.promotion_header.content
        } else {
          this.editedItem.params.promotion_header = { content: '', sort_priority: 1 }
        }
      }
    },

    setShowInMate(val) {
      if (val && this.currentTypeParams.params) {
        this.getMobileParams()
      } else {
        if (this.currentTypeParams.params.includes('promotion_header')) {
          this.editedItem.params.promotion_header.content = null
        }
      }
    },

    paramSwitchChange(value, key) {
      if (key === 'promotion_header') {
        this.promotionHeaderDisabled = !value
      }
      if (!value) this.editedItem.params.promotion_header.content = null
    },

    updateCondition(condition) {
      const { payload, index } = condition
      this.editedItem.conditions = this.editedItem.conditions.map((item, i) => (i === index ? payload : item))
    },

    deleteCondition(index) {
      this.editedItem.conditions = this.editedItem.conditions.filter((_, i) => i !== index)
    },

    toPromotions() {
      this.$v.$reset()
      this.$router.push({ name: 'Promotions', query: { tab: this.editedItem.is_active ? 'list' : 'arch' } })
    },

    async saveData() {
      this.loading = true
      const payload = CloneDeep({
        ...this.editedItem,
        amount: this.editedItem.amount !== null ? +this.editedItem.amount : null,
        devices: this.editedItem.devices.map((device) => ({ id: device.id })),
      })
      delete payload.id
      if (this.currentTypeParams.params && this.currentTypeParams.params.includes('promotion_header')) {
        payload.params.promotion_header = {
          ...payload.params.promotion_header,
          sort_priority: this.editedItem.params.flag_params.promotion_header.sort_priority,
        }
        delete payload.params.flag_params.promotion_header
      }
      try {
        if (this.promotionId) {
          await this.updateSelectedPromotion({
            companyId: this.currentCompanyId,
            isAdmin: this.isGlobal,
            id: this.promotionId,
            updatedPromotion: payload,
          })
        } else {
          await this.createPromotion({
            companyId: this.currentCompanyId,
            isAdmin: this.isGlobal,
            newPromotion: payload,
          })
        }
      } finally {
        this.loading = false
        this.toPromotions()
      }
    },

    saveClick() {
      this.$v.editedItem.$touch()
      if (this.$v.$anyError) return
      if (
        this.editedItem.conditions &&
        this.editedItem.conditions.length &&
        this.editedItem.conditions[this.editedItem.conditions.length - 1].condition_amount_to !== null
      ) {
        this.dialogAsk = true
      } else {
        if (this.editedItem.start_at)
          this.editedItem.start_at = moment(this.editedItem.start_at).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        if (this.editedItem.expired_at)
          this.editedItem.expired_at = moment(this.editedItem.expired_at).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.saveData()
      }
    },

    askYes() {
      this.editedItem.conditions[this.editedItem.conditions.length - 1].condition_amount_to = null
      this.dialogAsk = false
      this.saveData()
    },
  },
}
</script>

<style scoped lang="scss">
.error-msg {
  min-height: 12px;
  margin: 8px 0 0 8px;
  & > div {
    color: rgb(255, 82, 82);
    font-size: 12px;
    line-height: 1;
  }
}
</style>
