<template>
  <v-card flat>
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="text-center" colspan="2">Діапазон {{ index }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon :disabled="deleteDisabled" @click="deleteRange">
                    <v-icon small v-bind="attrs" v-on="on">mdi-delete</v-icon>
                  </v-btn>
                </template>
                Видалити
              </v-tooltip>
            </td>
          </tr>
          <tr>
            <td rowspan="2">Сума покупок, грн.</td>
            <td>Від</td>
            <td>До</td>
          </tr>
          <tr>
            <td class="text-right">
              <span v-if="!editing" class="font-weight-medium cursor-pointer" @click="edit">
                {{ editedItem.condition_amount_from }}
              </span>
              <v-text-field
                v-else
                v-model.number="editedItem.condition_amount_from"
                type="number"
                label="від"
                :disabled="fromDisabled"
                :error-messages="inputErrors('condition_amount_from')"
                @blur="$v.editedItem.condition_amount_from.$touch()"
                @input="$v.editedItem.condition_amount_from.$touch()"
              ></v-text-field>
            </td>
            <td class="text-right">
              <span v-if="!editing" class="font-weight-medium cursor-pointer" @click="edit">
                {{ editedItem.condition_amount_to }}
              </span>
              <v-text-field
                v-else
                v-model.number="editedItem.condition_amount_to"
                :disabled="toDisabled"
                type="number"
                label="до"
                :error-messages="inputErrors('condition_amount_to')"
                @blur="$v.editedItem.condition_amount_to.$touch()"
                @input="$v.editedItem.condition_amount_to.$touch()"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td>Тип бонуса</td>
            <td colspan="2">
              <span v-if="!editing" class="font-weight-medium cursor-pointer" @click="edit">
                {{ getValueType(editedItem.type) }}
              </span>
              <v-select
                v-else
                v-model="editedItem.type"
                :items="valueTypesData"
                :disabled="!!params.valueTypeOnly"
                item-text="value"
                item-value="id"
                label="Тип бонуса"
                :error-messages="inputErrors('type')"
                @blur="$v.editedItem.type.$touch()"
                @input="$v.editedItem.type.$touch()"
              />
            </td>
          </tr>
          <tr>
            <td>Розмір бонуса</td>
            <td colspan="2">
              <span v-if="!editing" class="font-weight-medium cursor-pointer" @click="edit">
                {{ editedItem.amount }}
              </span>
              <v-text-field
                v-else
                v-model.number="editedItem.amount"
                type="number"
                label="Розмір"
                :error-messages="inputErrors('amount')"
                @click="editing = true"
                @blur="$v.editedItem.amount.$touch()"
                @input="$v.editedItem.amount.$touch()"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-actions v-if="editing">
      <v-spacer />
      <v-btn text @click="save">Зберегти</v-btn>
      <v-btn text @click="cancel">Скасувати</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { valueTypes } from '@/const/promotionParams'
import { helpers, minValue, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  name: 'ConditionsArrayItem',

  mixins: [validationMixin],

  props: {
    condition: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    fromDisabled: {
      type: Boolean,
      default: false,
    },
    toDisabled: {
      type: Boolean,
      default: false,
    },
    deleteDisabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['save', 'edit', 'cancel'],

  data() {
    return {
      editedItem: null,
      editing: false,
    }
  },

  validations() {
    const isFromValid = helpers.withParams(
      { type: 'isFromValid' },
      (value, vm) => value <= vm.condition_amount_to || vm.condition_amount_to === null || vm.condition_amount_to === ''
    )
    const isToValid = helpers.withParams(
      { type: 'isToValid' },
      (value, vm) => value >= vm.condition_amount_from || value === null || value === ''
    )
    return {
      editedItem: {
        condition_amount_from: { required, isFromValid },
        condition_amount_to: { isToValid },
        type: { required },
        amount: { required, minValue: minValue(0.01) },
      },
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    condition(val) {
      this.editedItem = { ...val }
    },
  },

  computed: {
    valueTypesData() {
      return this.params.valueTypeOnly ? valueTypes.filter((item) => item.id === this.params.valueTypeOnly) : valueTypes
    },
  },

  methods: {
    initialize() {
      this.editedItem = { ...this.condition }
      this.editing = !this.readonly
    },

    inputErrors(fieldName) {
      const errors = []
      if (!this.$v.editedItem[fieldName].$dirty) return errors
      this.$v.editedItem[fieldName].required === false && errors.push('Це поле обов"язкове')
      if (
        this.$v.editedItem[fieldName].minValue === false ||
        this.$v.editedItem[fieldName].isToValid === false ||
        this.$v.editedItem[fieldName].isFromValid === false
      ) {
        errors.push('Неприпустиме значення')
      }
      return errors
    },

    getValueType(type) {
      const val = valueTypes.find((item) => item.id === type)
      return val?.value || null
    },

    edit() {
      this.editing = true
      this.$emit('edit')
    },

    save() {
      this.$v.editedItem.$touch()
      if (this.$v.$anyError) return
      this.$emit('save', this.editedItem)
      this.editing = false
    },

    deleteRange() {
      this.$emit('delete', this.index - 1)
    },

    cancel() {
      this.$v.editedItem.$reset()
      this.$emit('cancel')
      this.editing = false
    },
  },
}
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
